.GameSelect {
    background-color: #ffffff;
    max-width: 768px;
    margin: auto;
    margin-top: 1em;
    margin-bottom: 20px;
    min-height: 500px;
    text-align: center;
    align-content: center;
    border-radius: 1%;
    box-shadow: 0px 1px 3px #888888;
    overflow: hidden;
}

.QuickSelectNoCustom {
  display: flex;
  flex-direction: row;
}

.QuickSelectColumn {
  display: flex;
  width: 50%;
  flex-direction:column;
  flex: 1;
}

.QuickSelectBox {
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    background-color: #f7f7f7;
    min-height: 100px;
    border: solid;
    border-width: thin;
    border-color: #dddddd;
}

.QuickSelectText {
    margin: 0px;
}

.QuickPlayHeader {
    height: 3em;
    background-color: #eeeeee;
    overflow: hidden;
    box-shadow: 0px 1px 1px #888888;
}

.QuickPlayHeader h2 {
    Margin: 0px;
    padding-top: .25em;
    padding-left: .25em;
}

.QuickSelectBox:hover {
    background-color: #eeeeee;
}
