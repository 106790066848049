.LeaderboardsPage {
  background-color: #ffffff;
  max-width: 768px;
  margin: auto;
  margin-top: 1em;
  margin-bottom: 20px;
  min-height: 800px;
  text-align: center;
  align-content: center;
  border-radius: 1%;
  box-shadow: 0px 1px 3px #888888;
  overflow: scroll;
  overflow-x: hidden;
}
