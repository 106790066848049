.Leaderboard {
    display: flex;
    justify-content: center;
    max-width: 840px;
    margin: auto;
    margin-bottom: 30px;
}

.BothBoards {
    background-color: #ffffff;
    display: inline-block;
    min-height: 300px;
    width: 45%;
    box-shadow: 0px 1px 3px #888888;
}

.BoardContents {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.TourneyName {
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
  text-align: center;
  background-color: #ffffff;
  box-shadow: 0px 1px 2px #999999
}

.TourneyName h3 {
  margin: 0px;
}

.TourneyEntry {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  margin-top: 1px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #eeeeee;
  box-shadow: 0px 1px 2px #999999;
  width: 100%;
}

.TourneyEntry h3 {
  font-size: 1.1em;
  margin: 0px;
}

.Loser {
  width: 30%;
}

.Result {
  width: 30%;
}

.Winner {
  width: 30%;
}

.DarkOrange {
  color: darkorange;
}

.EloEntry {
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  padding-bottom: 5px;
  box-shadow: 0px 1px 2px #999999;
}

.EloEntry h3 {
  width: 40%;
  margin: 0px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.EloDescriptor {
  border-bottom: Solid;
  border-width: thin;
  border-color: #aaaaaa;
}

.BoardHeader {
    height: 2em;
    background-color: #eeeeee;
    text-align: center;
    overflow: hidden;
    box-shadow: 0px 1px 1px #888888;
}

.BoardHeader h3 {
    Margin: 0px;
    padding-top: .25em;
    padding-left: .25em;
}

#Leaderboard1 {
    margin-right: 1%;
}

@media screen and (max-width: 840px) {
  .Leaderboard {flex-direction: column}
  .BothBoards {width: 100%}
}
