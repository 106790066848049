.Left {
  float: left;
}

.Right {
  float: right;
}

.Left, .Right {
  width: 40%;
  display: flex;
  align-items: center;
  box-shadow: 0px 1px 3px #888888;
  background-color: #ffffff;
  margin-bottom: 10px;
  border-radius: 1em;
}

.Left h3, .Left h5, .Right h3, .Right h5 {
  margin: 0px;
  margin-left: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.Left h3, .Right h3 {
  font-size: 15px;
}

.Red, .Blue {
  width: 40px;
  height: 40px;
  border-style: solid;
  border-width: thin;
  border-color: #676767;
}

.Red {
  background-color: red;
}

.Blue {
    background-color: Blue;
}

.GoldName {
  color: darkorange;
}

.PlayerInfo {
  width: 80%;
  display: inline-block;
}

#TurnHighlight {
  border-style: solid;
  border-color: yellow;
}

@media screen and (max-width: 800px) {
  .Red, .Blue {width: 20px; height:20px}
  .Left h3, .Right h3 {font-size: 10px}
  .Left h5, .Right h5 {font-size: 10px}
}
