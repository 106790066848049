.Score {
  display: flex;
  align-items: center;
  margin: auto;
}

.Score p {
  display: inline-block;
  font-weight: bold;
  margin-bottom: 0px;
  margin-top: 0px;
  margin-left: 5px;
  margin-right: 5px;
  padding-bottom: 5px;
}

.ScoreNumber {
  font-size: 3em;
}

.R {
  color: red;
}

.B {
  color: blue;
}

.X {
  font-size: 2em;
}

.ScoreSeperator {
  display: inline-block;
  height: 15px;
  width: 15px;
  background-color: black;
  border-radius: 100%;
  margin-left: 10px;
  margin-right: 10px;
}

@media screen and (max-width: 800px) {
  .ScoreNumber {font-size: 2em}
  .ScoreSeperator {width: 10px; height: 10px; margin-left: 5px; margin-right: 5px}
}
