.CreateGame {
    background-color: #ffffff;
    max-width: 768px;
    margin: auto;
    margin-top: 1em;
    margin-bottom: 20px;
    max-height: 500px;
    text-align: center;
    border-radius: 1%;
    box-shadow: 0px 1px 3px #888888;
    overflow: hidden;

}

.createGameSettings {
}

.gameSetting {
  margin: 20px 0px;
  max-width: 300px;
  display: inline-block;
}

.startButton {
    background-color: #f7f7f7;
    max-width: 288px;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    box-shadow: 0px 1px 5px #888888;
    border-radius: 10px;
}

.startButtonText {
    margin: 0px;
    padding: 20px;
}

.createGame h3 {
  color: #676767;
}

select {
  border: 1px solid #676767;
  border-radius: 0.25em;
  padding: 0.25em 0.5em;
  font-size: 1.25rem;
  cursor: pointer;
  line-height: 1.1;
  background-color: #fff;
  background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
}

select::after {
  content: "";
  width: 0.8em;
  height: 0.5em;
  background-color: var(--select-arrow);
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
}

label {
  margin: 0px;
  padding: 20px;
  font-weight: bold;
  color: #676767;
  font-size: 1.2em;
}
