.GamePart {
  max-width: 700px;
  display: inline-block;
}
.ChatPart {
  display: flex;
  margin-left: 10px;
}

.ScoreAndUsers {
  display: flex;
  align-items: center;
}

.DotGameContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

@media screen and (max-width: 1100px) {
  .DotGameContainer {flex-direction: column}
  .GamePart {width: 100%}
  .ChatPart {width: 100%; margin: auto;}
}
