.OpenGameBoard {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    max-width: 768px;
    margin: auto;
    margin-top: 1em;
    margin-bottom: 20px;
    min-height: 500px;
    text-align: center;
    align-content: center;
    border-radius: 1%;
    box-shadow: 0px 1px 3px #888888;
    overflow: auto;
    overflow-x: hidden;

}

#NoGames {
  height: 100%;
  align-self: center;
  justify-self: stretch;
  opacity: 70%;
}

.Descriptors {
  border-bottom: Solid;
  border-width: thin;
  border-color: #aaaaaa;
  box-shadow: 0px 2px 4px black
}

.OpenGameEntry {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  padding-bottom: 5px;
  box-shadow: 0px 1px 2px #999999
}

.Odd {
  background-color: #eeeeee;
}

.Even {
  background-color: #ffffff;
}

.OpenGameEntry h3 {
  margin: 0;
}

.Type {
  width: 30%;
}

.Ranked {
  width: 30%;
}

.Opponent {
  width: 30%;
}
