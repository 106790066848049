.LearnPage {
  width: 100%;
}

.LearnBox {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  max-width: 768px;
  margin: auto;
  margin-top: 1em;
  margin-bottom: 20px;
  padding-bottom: 20px;
  min-height: 500px;
  align-content: center;
  border-radius: 1%;
  box-shadow: 0px 1px 3px #888888;
  overflow: auto;
  overflow-x: hidden;
}

.LearnText {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.LearnText h1, .LearnText h3 {
  margin-top: 20px;
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 0px;
}

.LearnText ol {
  margin: 0px;
  margin-top: 10px;
  margin-right: 10px;
}

.LearnText li {
  margin-top: 10px;
}
