.Login {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: auto;
  margin-top: 1em;
  margin-bottom: 20px;
  padding-bottom: 20px;
  align-content: center;
  text-align: center;
  border-radius: 1%;
  box-shadow: 0px 1px 3px #888888;
  overflow: auto;
  overflow-x: hidden;
}

.Login input {
  font-size: 1.2em;
  width: 80%;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.LoginButtons button {
  width: 40%;
  margin: 5px;
}

.LoginButtons button div:hover {
  cursor: pointer;
}

.LoginButtons button div {
  height: 50px;
  display: flex;
  align-content: center;
  justify-content: center;
}

.LoginError {
  border-style: solid;
  border-color: red;
  background-color: rgba(255, 0, 0, 0.2);
  width: 80%;
  margin: auto;
  margin-bottom: 10px;
}

.LoginError h1 {
  color: red;
}

.LoginError h3 {
  color: red;
  margin: 5px;
}
