.EndCardContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(150,150,150,.3);
  top: 0;
}

.EndCard {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 400px;
  height:300px;
  background-color: #ffffff;
  border-radius: 20%;
}

.EndCardClose {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;
  width: 150px;
  height: 75px;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  box-shadow: 0px 1px 5px #888888;
  border-radius: 10px;
  border: none;
}

.EndCardClose:hover {
  cursor: pointer;
}

.hidden {
  display: none;
}
