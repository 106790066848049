
a, h3{
    color: #676767;
    text-decoration: none;
}

.header li:hover{
    background-color: #eeeeee;
}

body {
    background-color: #f7f7f7;
    margin: 0px;
}

/* Header */

.header {
    font-family: "Arial", sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    line-height: 1.25;
    background-color: #eeeeee;
    width: 100%;
    font-weight: 900;
    text-align: center;
    box-shadow: 0px 1px 3px #888888;
}

.header ul {
    margin: 0px;
    padding: 0px;
}

.header ul div {
    display: inline-block;
}

.header li {
    background-color: #f7f7f7;
    display: inline-block;
    padding: 10px 20px;
    box-shadow: 1px 0px 3px #888888;
}

#navButtons {
    margin: auto;
    display: block;
}

#loginButton {
  margin-left: auto;
  margin-right: 5px;
}

#headerLogo {
  float: center;
  max-width: 400px;
  margin-top: 5px;
  margin-bottom: 5px;
}

#headerLogo img {
  object-fit: fill;
  width: 100%;
}

@media screen and (max-width: 950px) {
  #headerLogo {display:none}
  .header ul {flex: 1}
  .header {justify-content: center}
  #navButtons {margin: 0px}
  #loginButton {margin: none}
}
