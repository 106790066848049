.GameBoard {
  background-color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0px;
  margin: auto;
  width: 700px;
  aspect-ratio: 1/1;
  box-shadow: 0px 1px 3px #888888;
  border-radius: 1em;
}

.BoardRow {
  display:flex;
}

.YLine {
  width: 10px;
  height: 40px;
}

.XLine {
  width: 40px;
  height: 10px;
}

.Dot {
  width: 10px;
  height: 10px;
  background-color: black;
}

.Box {
  width: 40px;
  height: 40px;
}

.YLine:hover, .XLine:hover {
  cursor: pointer;
}

#none {
  background-color: none;
}

#yellow {
  background-color: yellow;
}

#black {
  background-color: black;
}

#green {
  background-color: lightgreen;
}

#red {
  background-color: red;
}

#blue {
  background-color: blue;
}

@media screen and (max-width: 800px) {
  .GameBoard {width: 100%}
  .XLine {width: 20px; height: 5px}
  .YLine {height: 20px; width: 5px}
  .Dot   {width: 5px; height: 5px}
  .Box   {width: 20px; height: 20px}
}
