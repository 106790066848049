.ChatBox {
  background-color: #ffffff;
  display: inline-block;
  position: relative;
  margin: auto;
  width: 300px;
  height: 760px;
  box-shadow: 0px 1px 3px #888888;
  border-radius: 1em;
}

#ChatWindow {
  height: 90%;
  overflow: scroll;
  overflow-x: hidden;
}

#ChatWindow p {
  margin-top: 0px;
  margin-left: 5px;
  margin-bottom: 5px;
}

.ChatEntry {
  display: flex;
  width: 100%;
  position: absolute;
  bottom: 0px;
}

.ChatEntry input[type="text"]{
  width: 90%;
  margin: auto;
  margin-bottom: 5px;
  font-size: 1.2em;
  border-radius: 8px;
}

@media screen and (max-width: 1100px) {
  .ChatBox {width: 700px; height: 300px; margin-top: 20px;}
  #ChatWindow {height: 75%}
}
